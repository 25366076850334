/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

var $findme = $('#numbers');
var exec = false;
function Scrolled() {
  $findme.each(function() {
    var $section = $(this),
      findmeOffset = $section.offset(),
      findmeTop = findmeOffset.top,
      findmeBottom = $section.height() + findmeTop,
      scrollTop = $(document).scrollTop(),
      visibleBottom = window.innerHeight,
      prevVisible = $section.prop('_visible');

    if ((findmeTop > scrollTop + visibleBottom) || findmeBottom < scrollTop) {
      visible = false;
    } else { 
	    visible = true;
	  }

    if (!prevVisible && visible) {
    	if(!exec){
              $('.fig-number').each(function() {
          $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
          }, {
            duration: 2000,

            step: function(now) {
              $(this).text(Math.ceil(now));
              exec = true;
            }
          });
        });
      }
    }
    $section.prop('_visible', visible);
  });

}

function Setup() {
  var $top = $('#top'),
    $bottom = $('#bottom');

  $top.height(500);
  $bottom.height(500);

  $(window).scroll(function() {
    new Scrolled();
  });
}
new Setup();

				var $menu = $("#mobile-menu").mmenu({
	      	navbar: {
						title: 'Moorpark Vet'
					},					
					"extensions": [
						"shadow-page",
						
						"theme-light",
						"pagedin"
					]
				});
				var $icon = $("#open-close-menu");
				var API = $menu.data( "mmenu" );
				
				$icon.on( "click", function() {
				   API.open();
				});
				
				API.bind( "open:finish", function() {
				   setTimeout(function() {
				      $icon.addClass( "is-active" );
				   }, 100);
				});
				API.bind( "close:finish", function() {
				   setTimeout(function() {
				      $icon.removeClass( "is-active" );
				   }, 100);
				});
				
				$(".hero-carousel").owlCarousel({
			    nav:true,
			    dots:true,
			    autoHeight:false,
			    animateIn: 'fadeIn',
			    animateOut: 'fadeOut',
					navText: [
					   "<i class='fas fa-angle-left'></i>",
					   "<i class='fas fa-angle-right'></i>"
					],
			    responsive:{
		        0:{
	            items:1
		        },
		        768:{
	            items:1,
	            slideBy:1,
	            margin:0
		        }
			    }					
				});

				$(".gallery-carousel").owlCarousel({
			    nav:true,
			    dots:true,
			    autoHeight:false,
			    animateIn: 'fadeIn',
			    animateOut: 'fadeOut',
			    autoplay:true,
					navText: [
					   "<i class='fas fa-angle-left'></i>",
					   "<i class='fas fa-angle-right'></i>"
					],
			    responsive:{
		        0:{
	            items:1
		        },
		        768:{
	            items:1,
	            slideBy:1,
	            margin:0
		        }
			    }					
				});

				$(".latest-posts-carousel").owlCarousel({
			    nav:true,
			    dots:false,
			    margin:0,
			    autoplay: false,
					navText: [
					   "<i class='fas fa-angle-left'></i>",
					   "<i class='fas fa-angle-right'></i>"
					],
			    responsive:{
		        0:{
	            items:2
		        },
		        768:{
	            items:4,
	            slideBy:4
		        },
		        992:{
	            items:6,
	            slideBy:6
		        },
		        1441:{
	            items:8,
	            slideBy:8
		        }
			    }					
				});
				
/*
				$('.in-view .count').each(function() {
				  $(this).prop('Counter', 0).animate({
				    Counter: $(this).text()
				  }, {
				    duration: 2000,
				    easing: 'swing',
				    step: function(now) {
				      $(this).text(Math.ceil(now));
				    }
				  });
				});
*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
